import React from "react";
import html2pdf from "html2pdf.js";
import { buttonsStyle } from "../../../components/tables/table";
import { Button, Tooltip } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const JsonToPdf = ({ jsonData }) => {
  const generateHtml = () => {
    const data = jsonData;

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    };

    const renderIfValid = (value, htmlContent) => {
      return value && value !== "" && value !== undefined ? htmlContent : "";
    };

    let html = `
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css" rel="stylesheet" />
      <style>
        .logo-container {
            flex-shrink: 0;
        }

        .logo-img {
            width: 100px; 
            height: auto;
        }
        .container {
          overflow-x: hidden;
          word-wrap: break-word;
          max-width: 100%; 
          overflow-x: hidden;
        }
        .container2 {
          margin-right: 10px;
        }
        .report-container {
          background-color: #f9f9f9;
          margin: 20px;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        .section-title {
          background-color: #0a73ad;
          color: #fff;
          margin: 10px;
          padding: 5px;
          border-radius: 4px;
          text-align: center;
          font-weight: bold;
        }
        .data-label {
          font-weight: bold;
        }
        .list-group-item {
          margin: 10px;
          border-radius: 5px;
        }
        .container, .row, .list-group-item, .group {
          page-break-inside: avoid;  /* Evita los saltos dentro de estos contenedores */
        }
      </style>

      <div class="container report-container my-4">
          <div class="logo-container">
              <img
                src=${toAbsoluteUrl("/media/logo/logo.png")}
                alt="logo"
                class="logo-img"
              />
          </div>
          <h1 class="text-center mb-4">Informe del Cachorro: ${data?.name ||
            "No disponible"}</h1>
          
          <div class="container2 mb-4">
            <h2 class="section-title">Información General</h2>
            <div class="row">
              <div class="col-md-6">
                ${renderIfValid(
                  data?.gender,
                  `<p><span class="data-label">Género:</span> ${data?.gender}</p>`
                )}
                ${renderIfValid(
                  data?.color,
                  `<p><span class="data-label">Color:</span> ${data?.color}</p>`
                )}
                ${renderIfValid(
                  data?.birthdate,
                  `<p><span class="data-label">Fecha de nacimiento:</span> ${formatDate(
                    data?.birthdate
                  )}</p>`
                )}
                ${renderIfValid(
                  data?.breeder,
                  `<p><span class="data-label">Criador:</span> ${data?.breeder}</p>`
                )}
                ${renderIfValid(
                  data?.chip,
                  `<p><span class="data-label">Microchip:</span> ${data?.chip}</p>`
                )}
                ${renderIfValid(
                  data?.mother?.name,
                  `<p><span class="data-label">Madre:</span> ${data?.mother?.name}</p>`
                )}
                ${renderIfValid(
                  data?.father?.name,
                  `<p><span class="data-label">Padre:</span> ${data?.father?.name}</p>`
                )}
                ${renderIfValid(
                  data?.category?.title,
                  `<p><span class="data-label">Raza:</span> ${data?.category?.title}</p>`
                )}
                ${renderIfValid(
                  data?.loe,
                  `<p><span class="data-label">LOE:</span> ${data?.loe}</p>`
                )}
                <p><span class= "data-label">Leishmania:</span> ${
                  data?.leishmania ? "Positivo" : "Negativo"
                }</p>
                ${renderIfValid(
                  data?.active,
                  `<p><span class="data-label">Estado Activo:</span> ${
                    data?.active ? "Sí" : "No"
                  }</p>`
                )}
              </div>
            </div>
          </div>

          <div class="container2 mb-4">
            <h2 class="section-title">Información sobre Cachorros</h2>
            <div class="group">
            ${renderIfValid(
              data?.nCub?.value,
              `<p><span class="data-label">Nacidos:</span> ${
                data?.nCub?.value
              } ${renderIfValid(
                data?.nCub?.observations,
                `<br><span class="data-label">Observaciones:</span> ${data?.nCub?.observations}`
              )}</p>`
            )}
            ${renderIfValid(
              data?.nDeadCub?.value,
              `<p><span class="data-label">Muertos:</span> ${
                data?.nDeadCub?.value
              } ${renderIfValid(
                data?.nDeadCub?.observations,
                `<br><span class="data-label">Observaciones:</span> ${data?.nDeadCub?.observations}`
              )}</p>`
            )}
            
              ${
                !data?.nCub?.value && !data?.nDeadCub?.value
                  ? "<p>No hay cachorros registrados.</p>"
                  : ""
              }
            </div>
          </div>

          <div class="container2 mb-4">
            <h2 class="section-title">Información de Alta</h2>
            <div class="group">
              ${renderIfValid(
                data?.acquisition?.data,
                `<p><span class="data-label">Fecha:</span> ${formatDate(
                  data?.acquisition?.data
                )}<br>${renderIfValid(
                  data?.acquisition?.observations,
                  `<span class="data-label">Observaciones:</span> ${data?.acquisition?.observations}`
                )}</p>`
              )}
              ${
                !data?.acquisition?.data && !data?.acquisition?.observations
                  ? "<p>No hay fechas de alta registrada.</p>"
                  : ""
              }
            </div>
          </div>

          <div class="container2 mb-4">
            <h2 class="section-title">Información de Baja</h2>
            <div class="group">
              ${renderIfValid(
                data?.departure?.data,
                `<p><span class="data-label">Fecha:</span> ${formatDate(
                  data?.departure?.data
                )}<br>${renderIfValid(
                  data?.departure?.observations,
                  `<span class="data-label">Observaciones:</span> ${data?.departure?.observations}`
                )}</p>`
              )}
              ${
                !data?.departure?.data && !data?.departure?.observations
                  ? "<p>No hay fechas de baja registrada.</p>"
                  : ""
              }
            </div>
          </div>

          <div class="container2 mb-4">
            <h2 class="section-title">Historial de Celo</h2>
            ${renderIfValid(
              data?.heat?.length,
              `
              <ul class="list-group">
                ${data?.heat
                  .map(
                    (item) => `
                  <li class="list-group-item">
                    <strong>Fecha:</strong> ${formatDate(item?.data)}<br>
                    ${renderIfValid(
                      item?.observations,
                      `<strong>Observaciones:</strong> ${item?.observations}`
                    )}
                  </li>
                `
                  )
                  .join("")}
              </ul>
            `
            )}
            ${
              !data?.heat?.length
                ? "<p>No hay fechas de celo registradas.</p>"
                : ""
            }
          </div>

          <div class="container2 mb-4">
            <h2 class="section-title">Historial de Montas</h2>
            ${renderIfValid(
              data?.breeding?.length,
              `
              <ul class="list-group container2">
                ${data?.breeding
                  .map(
                    (item) => `
                  <li class="list-group-item">
                    <strong>Fecha:</strong> ${formatDate(item?.data)}<br>
                    ${renderIfValid(
                      item?.observations,
                      `<strong>Observaciones:</strong> ${item?.observations}`
                    )}
                  </li>
                `
                  )
                  .join("")}
              </ul>
            `
            )}
            ${
              !data?.breeding?.length
                ? "<p>No hay fechas de montas registradas.</p>"
                : ""
            }
          </div>

          <div class="container2 mb-4">
            <h2 class="section-title">Historial de Posibles partos</h2>
            ${renderIfValid(
              data?.potentialBirthDates?.length,
              `
              <ul class="list-group container2">
                ${data?.potentialBirthDates
                  .map(
                    (item) => `
                  <li class="list-group-item">
                    <strong>Fecha:</strong> ${formatDate(item?.data)}<br>
                    ${renderIfValid(
                      item?.observations,
                      `<strong>Observaciones:</strong> ${item?.observations}`
                    )}
                  </li>
                `
                  )
                  .join("")}
              </ul>
            `
            )}
            ${
              !data?.potentialBirthDates?.length
                ? "<p>No hay fechas de posibles partos registradas.</p>"
                : ""
            }
          </div>

          <div class="container2 mb-4">
            <h2 class="section-title">Historial de Partos</h2>
            ${renderIfValid(
              data?.birthDates?.length,
              `
              <ul class="list-group container2">
                ${data?.birthDates
                  .map(
                    (item) => `
                  <li class="list-group-item">
                    <strong>Fecha:</strong> ${formatDate(item?.data)}<br>
                    ${renderIfValid(
                      item?.observations,
                      `<strong>Observaciones:</strong> ${item?.observations}`
                    )}
                  </li>
                `
                  )
                  .join("")}
              </ul>
            `
            )}
            ${
              !data?.breeding?.length
                ? "<p>No hay fechas de partos registradas.</p>"
                : ""
            }
          </div>

          <div class="container2 mb-4">
            <h2 class="section-title">Tratamientos</h2>
            ${renderIfValid(
              data?.treatments?.length,
              `
              <ul class="list-group container2">
                ${data?.treatments
                  .map(
                    (treatment) => `
                  <li class="list-group-item">
                    <h4>${treatment?.type}</h4>
                    ${renderIfValid(
                      treatment?.medication,
                      `<p><strong>Medicación:</strong> ${treatment?.medication}</p>`
                    )}
                    ${renderIfValid(
                      treatment?.indications,
                      `<p><strong>Indicaciones:</strong> ${treatment?.indications}</p>`
                    )}
                    ${renderIfValid(
                      treatment?.posology,
                      `<p><strong>Posología:</strong> ${treatment?.posology}</p>`
                    )}
                    ${renderIfValid(
                      treatment?.startDate,
                      `<p><strong>Fecha de inicio:</strong> ${formatDate(
                        treatment?.startDate
                      )}</p>`
                    )}
                  </li>
                `
                  )
                  .join("")}
              </ul>
            `
            )}
            ${
              !data?.treatments?.length
                ? "<p>No hay tratamientos registrados.</p>"
                : ""
            }
          </div>
      </div>
    `;

    return html;
  };

  const handleGeneratePdf = () => {
    const htmlContent = generateHtml();
    const element = document.createElement("div");
    element.innerHTML = htmlContent;

    html2pdf()
      .set({
        filename: `Animal_${jsonData.name}_microchip_${jsonData.chip}.pdf`,
        html2canvas: { scale: 2 }, // Ajusta la escala para mayor calidad
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { mode: "avoid-all" }, // Evita que los cortes se realicen en medio de elementos
      })
      .from(element)
      .save(`Animal_${jsonData.name}_microchip_${jsonData.chip}.pdf`);
  };

  return (
    <Tooltip title="Download PDF">
      <Button style={buttonsStyle} size="small" onClick={handleGeneratePdf}>
        <CloudDownload />
      </Button>
    </Tooltip>
  );
};

export default JsonToPdf;
