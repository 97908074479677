import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";
import { shallowEqual, useSelector } from "react-redux";
import { DashboardPage } from "./pages/DashboardPage";
import AdminsPage from "./modules/ContentManager/admins/AdminsPage";
import EditAdminsPage from "./modules/ContentManager/admins/EditAdminsPage";
import TextsPage from "./modules/ContentManager/texts/TextsPage";
import EditTextsPage from "./modules/ContentManager/texts/EditTextsPage";
import LanguagesPage from "./modules/ContentManager/languages/LanguagesPage";
import EditLanguagesPage from "./modules/ContentManager/languages/EditLanguagesPage";
import ContactsPage from "./modules/ContentManager/contacts/ContactsPage";
import EditContactsPage from "./modules/ContentManager/contacts/EditContactsPage";
import NewsLettersPage from "./modules/ContentManager/newsLetters/NewsLettersPage";
import EditNewsLettersPage from "./modules/ContentManager/newsLetters/EditNewsLettersPage";
import CampaignsPage from "./modules/ContentManager/campaigns/CampaignsPage";
import EditCampaignsPage from "./modules/ContentManager/campaigns/EditCampaignsPage";
import OriginsPage from "./modules/ContentManager/origins/OriginsPage";
import EditOriginsPage from "./modules/ContentManager/origins/EditOriginsPage";
import CategoriesPage from "./modules/ContentManager/categories/CategoriesPage";
import EditCategoriesPage from "./modules/ContentManager/categories/EditCategoriesPage";
import ProvinciasPage from "./modules/ContentManager/provincias/ProvinciasPage";
import EditProvinciasPage from "./modules/ContentManager/provincias/EditProvinciasPage";
import LocalidadesPage from "./modules/ContentManager/localidades/LocalidadesPage";
import EditLocalidadesPage from "./modules/ContentManager/localidades/EditLocalidadesPage";
import ComunidadesPage from "./modules/ContentManager/comunidades/ComunidadesPage";
import EditComunidadesPage from "./modules/ContentManager/comunidades/EditComunidadesPage";
import ImportDataPage from "./modules/ContentManager/importData/ImportDataPage";
import LogsPage from "./modules/ContentManager/logs/LogsPage";
import UpdatesPage from "./modules/ContentManager/updates/UpdatesPage";
import ProductsPage from "./modules/ContentManager/products/ProductsPage";
import StoreOrdersPage from "./modules/ContentManager/orders/OrdersPage";
import StoreCategoriesPage from "./modules/ContentManager/storeCategories/StoreCategories";
import ContractsPage from "./modules/ContentManager/contratos/ContractsPage";
import EditContractsPage from "./modules/ContentManager/contratos/EditContractsPage";
import ChargesPage from "./modules/ContentManager/charges/ChargesPage";
import EditChargesPage from "./modules/ContentManager/charges/EditChargesPage";
import PersonalPage from "./modules/ContentManager/personal/PersonalPage";
import EditPersonalPage from "./modules/ContentManager/personal/EditPersonalPage";
import PetsPage from "./modules/ContentManager/pets/PetsPage";
import EditPetsPage from "./modules/ContentManager/pets/EditPetsPage";

export default function BasePage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const isAdmin = user || user?.role !== "admin";

  return (
    <Switch>
      {/* Redirect from root URL to /dashboard. */}
      <Redirect exact from="/" to="/dashboard" />
      <ContentRoute path="/dashboard" component={DashboardPage} />
      {/* USERS */}
      {/* Administrators */}
      <ContentRoute from="/admins" component={AdminsPage} />
      <ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />
      {/* MANAGEMENT */}
      {/* Campaigns */}
      <ContentRoute from="/campaigns" component={CampaignsPage} />
      <ContentRoute from="/edit-campaign/:id?" component={EditCampaignsPage} />
      {/* Contacts */}
      <ContentRoute from="/contacts" component={ContactsPage} />
      <ContentRoute from="/edit-contact/:id?" component={EditContactsPage} />
      {/* News Letters */}
      <ContentRoute from="/newsLetters" component={NewsLettersPage} />
      <ContentRoute
        from="/edit-newsLetter/:id?"
        component={EditNewsLettersPage}
      />
      {/* Import Data */}
      <ContentRoute from="/import-data" component={ImportDataPage} />
      <ContentRoute
        from="/edit-import-data/:id?"
        component={EditNewsLettersPage}
      />
      {/* PERSONAL */}
      <ContentRoute from="/personal" component={PersonalPage} />
      <ContentRoute from="/edit-personal/:id?" component={EditPersonalPage} />

      {/* ContactsPage */}
      <ContentRoute from="/contracts" component={ContractsPage} />
      <ContentRoute from="/edit-contract/:id?" component={EditContractsPage} />

      {/* Pet */}
      <ContentRoute from="/pets" component={PetsPage} />
      <ContentRoute from="/edit-pet/:id?" component={EditPetsPage} />

      {/* MISCELLANY */}
      {/* Category */}
      <ContentRoute from="/categories" component={CategoriesPage} />
      <ContentRoute from="/edit-category/:id?" component={EditCategoriesPage} />

      {/* Charges */}
      <ContentRoute from="/charges" component={ChargesPage} />
      <ContentRoute from="/edit-charge/:id?" component={EditChargesPage} />

      {/* Product */}
      <ContentRoute from="/products" component={ProductsPage} />

      {/* Store Categories */}
      <ContentRoute from="/store-categories" component={StoreCategoriesPage} />

      {/* Store Orders */}
      <ContentRoute from="/store-orders" component={StoreOrdersPage} />

      {/* Campaigns */}
      <ContentRoute from="/campaigns" component={CampaignsPage} />
      <ContentRoute from="/edit-campaign/:id?" component={EditCampaignsPage} />
      {/* Origin */}
      <ContentRoute from="/origins" component={OriginsPage} />
      <ContentRoute from="/edit-origin/:id?" component={EditOriginsPage} />

      {/* Provincias */}
      <ContentRoute from="/provincias" component={ProvinciasPage} />
      <ContentRoute
        from="/edit-provincia/:id?"
        component={EditProvinciasPage}
      />

      {/* Localidades */}
      <ContentRoute from="/localidades" component={LocalidadesPage} />
      <ContentRoute
        from="/edit-localidad/:id?"
        component={EditLocalidadesPage}
      />

      {/* Comunidades */}
      <ContentRoute from="/comunidades" component={ComunidadesPage} />
      <ContentRoute
        from="/edit-comunidad/:id?"
        component={EditComunidadesPage}
      />
      {/* Languages */}
      <ContentRoute
        from="/languages"
        component={!isAdmin ? DashboardPage : LanguagesPage}
      />
      <ContentRoute
        from="/edit-language/:id?"
        component={!isAdmin ? DashboardPage : EditLanguagesPage}
      />
      {/* Logs */}
      <ContentRoute from="/logs" component={LogsPage} />
      {/* Texts */}
      <ContentRoute
        from="/texts"
        component={!isAdmin ? DashboardPage : TextsPage}
      />
      <ContentRoute
        from="/edit-text/:id?"
        component={!isAdmin ? DashboardPage : EditTextsPage}
      />
      {/* Updates */}
      <ContentRoute
        from="/updates"
        component={!isAdmin ? DashboardPage : UpdatesPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
