import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getPets, deletePet, changeStatusPet } from "../../../../api/pet";
import {
  Button,
  Tooltip,
  Switch,
  FormControlLabel,
  IconButton,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EventIcon from "@material-ui/icons/Event";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import { shallowEqual, useSelector } from "react-redux";
import FiltersCard from "../../../components/filters/Filter";
import { postLog } from "../../../../api/log";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FullCalendarView from "../../../components/petsTreatment/FullCalendarView";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import JsonToPdf from "./JsonToPdf";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getTypeOptions() {
  return {
    Nacimiento: "birthday",
    Celo: "heat",
    Monta: "breeding",
    Parto: "birthsDates",
    "Posible Parto": "potentialBirthDates",
    Alta: "acquisition",
    Baja: "departure",
    Vacuna: "treatments.Vacuna",
    Desparasitación: "treatments.Desparasitación",
    Medicación: "treatments.Medicación",
    Otros: "treatments.Otros",
  };
}
const calculateAge = (birthdate) => {
  const birthDateObj = new Date(birthdate);
  const today = new Date();
  let age = today.getFullYear() - birthDateObj.getFullYear();

  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }

  return age;
};
function handleEvent(value, pet, date) {
  let ok = false;
  let type;
  const parts = value.includes(".") ? value.split(".") : [value, null];

  value = parts[0];
  type = parts[1];

  switch (value) {
    case "birthday":
      if (date !== "") {
        const birthdate = pet?.birthdate
          ? new Date(pet?.birthdate).toISOString().split("T")[0]
          : "";
        ok = birthdate === date;
      } else {
        ok = true;
      }
      break;
    case "acquisition":
      if (date !== "") {
        ok = pet.acquisition.data === date;
      } else {
        ok = true;
      }
      break;
    case "departure":
      if (date !== "") {
        ok = pet.departure.data === date;
      } else {
        ok = true;
      }
      break;
    case "heat":
      if (pet.heat?.length > 0 && date !== "") {
        for (const item of pet.heat) {
          if (item.data === date) {
            ok = true;
            break;
          }
        }
      } else if (pet.heat?.length > 0) {
        ok = true;
      }
      break;
    case "breeding":
      if (pet.breeding?.length > 0 && date !== "") {
        for (const item of pet.breeding) {
          if (item.data === date) {
            ok = true;
            break;
          }
        }
      } else if (pet.breeding?.length > 0) {
        ok = true;
      }
      break;
    case "birthsDates":
      if (pet.birthDates?.length > 0 && date !== "") {
        for (const item of pet.birthDates) {
          if (item.data === date) {
            ok = true;
            break;
          }
        }
      } else if (pet.birthDates?.length > 0) {
        ok = true;
      }
      break;
    case "potentialBirthDates":
      if (pet.potentialBirthDates?.length > 0 && date !== "") {
        for (const item of pet.potentialBirthDates) {
          if (item.data === date) {
            ok = true;
            break;
          }
        }
      } else if (pet.potentialBirthDates?.length > 0) {
        ok = true;
      }
      break;
    case "treatments":
      if (pet.treatments?.length > 0 && date !== "") {
        for (const item of pet.treatments) {
          if (item.startDate === date && item.type === type) {
            ok = true;
            break;
          }
        }
      } else if (pet.treatments?.length > 0) {
        for (const item of pet.treatments) {
          if (item.type === type) {
            ok = true;
            break;
          }
        }
      }
      break;
    default:
      ok = false;
      break;
  }
  return ok;
}
function machDate(pet, date) {
  const birthdate = pet?.birthdate
    ? new Date(pet?.birthdate).toISOString().split("T")[0]
    : "";
  if (birthdate === date) return true;
  if (pet.acquisition.data === date) return true;
  if (pet.departure.data === date) return true;
  if (pet.heat?.length > 0) {
    for (const item of pet.heat) {
      if (item.data === date) {
        return true;
      }
    }
  }
  if (pet.breeding?.length > 0) {
    for (const item of pet.breeding) {
      if (item.data === date) {
        return true;
      }
    }
  }
  if (pet.birthDates?.length > 0) {
    for (const item of pet.birthDates) {
      if (item.data === date) {
        return true;
      }
    }
  }
  if (pet.potentialBirthDates?.length > 0) {
    for (const item of pet.potentialBirthDates) {
      if (item.data === date) {
        return true;
      }
    }
  }

  if (pet.treatments?.length > 0) {
    for (const item of pet.treatments) {
      if (item.startDate === date) {
        return true;
      }
    }
  }
  return false;
}

function getData(filterOptions, pets) {
  let data = [];
  const stringNull = "---------";
  for (let i = 0; i < pets.length; ++i) {
    const elem = {};
    elem.name = pets[i]?.name;
    elem.age = pets[i]?.birthdate
      ? calculateAge(pets[i]?.birthdate)
      : stringNull;
    elem.births = pets[i].birthDates?.length;
    elem.lastBirth = pets[i]?.birthDates?.at(-1)?.data
      ? new Date(pets[i]?.birthDates?.at(-1)?.data).toISOString().split("T")[0]
      : stringNull;
    elem.id = pets[i]?._id;
    elem.active = pets[i]?.active;
    elem.gender = pets[i]?.gender;
    elem.color = pets[i]?.color;
    elem.birthdate = pets[i]?.birthdate
      ? new Date(pets[i]?.birthdate).toISOString().split("T")[0]
      : stringNull;
    elem.loe = pets[i]?.loe;
    elem.chip = pets[i]?.chip;
    elem.breeder = pets[i]?.breeder;
    elem.leishmania = pets[i]?.leishmania;
    elem.nCub = pets[i]?.nCub;
    elem.nDeadCub = pets[i]?.nDeadCub;
    elem.acquisition = pets[i]?.acquisition;
    elem.departure = pets[i]?.departure;
    elem.category = pets[i]?.category; //raza
    elem.mother = pets[i]?.mother;
    elem.father = pets[i]?.father;
    elem.heat = pets[i]?.heat;
    elem.breeding = pets[i]?.breeding;
    elem.birthDates = pets[i]?.birthDates;
    elem.potentialBirthDates = pets[i]?.potentialBirthDates;
    elem.treatments = pets[i]?.treatments;
    elem.active = pets[i]?.active;
    const isFilterMatched =
      (!filterOptions.name || filterOptions.name === elem.name) &&
      (!filterOptions.raza || filterOptions.raza === elem.raza) &&
      (!filterOptions.chip || filterOptions.chip === elem.chip);
    if (isFilterMatched) {
      data = data.concat(elem);
    }
    // else if (filterOptions.typeEvent?.length > 0) {
    //   for (const [label, value] of Object.entries(filterOptions.typeEvent)) {
    //     if (handleEvent(value, elem, filterOptions.startDate)) {
    //       data = data.concat(elem);
    //       break;
    //     }
    //   }
    // } else {
    //   if (machDate(elem, filterOptions.startDate)) data = data.concat(elem);
    // }
  }
  return data;
}

const initialFilters = {
  name: "",
  category: "",
  chip: "",
  startDate: "",
  typeEvent: [],
  optionsEvents: getTypeOptions(),
  active: false,
};

const initialCheckBox = {
  Celo: true,
  Monta: true,
  Parto: true,
  "Posible parto": true,
  Vacuna: true,
  Desparasitación: true,
  Medicación: true,
  Otros: true,
};
export default function PetsPage() {
  const [data, setData] = useState([]);
  const [petId, setPetId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [pets, setPets] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [collapsedCalendar, setCollapsedCalendar] = useState(true);
  const [listView, setListView] = useState(true);
  const [filterOptions, setFilterOptions] = useState(initialFilters);
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [checkBoxOptions, setCheckBoxOptions] = useState(initialCheckBox);

  useEffect(() => {
    console.log("user", user);
  }, []);

  function buttonFormatter(cell) {
    const elem = data.find((item) => item._id === cell);
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-pet/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {user?.role === "admin" && (
          <>
            <Tooltip title={elem?.active ? "Disable" : "Enable"}>
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setPetId(elem);
                  setOpenConfirmDialog(1);
                }}
              >
                {elem?.active ? (
                  <ToggleOffIcon />
                ) : (
                  <ToggleOnIcon style={{ color: "red" }} />
                )}
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setPetId(cell);
                  setOpenConfirmDialog(2);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
            <JsonToPdf jsonData={elem} />
          </>
        )}
      </>
    );
  }

  const columns = [
    { dataField: "name", text: "Nombre", sort: true },
    { dataField: "age", text: "Edad", sort: true },
    { dataField: "births", text: "Numero de Partos", sort: true },
    { dataField: "lastBirth", text: "Ultimo Partos", sort: true },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  useEffect(() => {
    getPets()
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setPets(res.data);
          setRefresh(false);
          postLog("pets-list", "visualize", user.fullName);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get pets.",
        });
      });
  }, [refresh]);

  const handleClearFilters = () => {
    setFilterOptions(initialFilters);
    setRefresh(true);
  };

  const handleSeletAllCalendar = () => {
    setCheckBoxOptions({
      Celo: true,
      Monta: true,
      Parto: true,
      "Posible parto": true,
      Vacuna: true,
      Desparasitación: true,
      Medicación: true,
      Otros: true,
    });
  };
  const handleRemoveSeletCalendar = () => {
    setCheckBoxOptions({
      Celo: false,
      Monta: false,
      Parto: false,
      "Posible parto": false,
      Vacuna: false,
      Desparasitación: false,
      Medicación: false,
      Otros: false,
    });
  };
  const handleChangeCheckBox = (event) => {
    const { value } = event.target;
    setCheckBoxOptions((prevCheckBoxOptions) => ({
      ...prevCheckBoxOptions,
      [value]: !prevCheckBoxOptions[value],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterOptions({
      ...filterOptions,
      [name]: value,
    });
  };

  const renderFiltersContent = () => {
    return (
      <>
        {/* Filter by name */}
        <TextField
          label="Nombre"
          name="name"
          value={filterOptions.name}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
        />

        {/* Filter by breed */}
        <TextField
          label="Raza"
          name="category"
          value={filterOptions.category}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
        />

        {/* Filter by chip */}
        <TextField
          label="Microchip"
          name="chip"
          value={filterOptions.chip}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
        />
        {/* Filter by events
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="evento-select-label">Tipo de evento</InputLabel>
          <Select
            multiple
            labelId="evento-select-label"
            id="evento-select"
            name="typeEvent"
            value={filterOptions.typeEvent}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={(event) => {
              handleInputChange(event);
            }}
            MenuProps={MenuProps}
          >
            {Object.entries(filterOptions.optionsEvents).map(
              ([label, value]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl> */}
        {/* Filter by date */}
        {/* <TextField
          label="Fecha del evento"
          type="date"
          name="startDate"
          value={filterOptions.startDate}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        /> */}
        <br />
        {/* Filter by active */}
        <FormControlLabel
          control={
            <Switch
              checked={filterOptions.active}
              onChange={() =>
                setFilterOptions({
                  ...filterOptions,
                  active: !filterOptions.active,
                })
              }
              name="checkActive"
            />
          }
          label={filterOptions.active ? "Only active" : "All active/inactive"}
        />
        <br />
      </>
    );
  };

  const renderCheckBoxContent = () => {
    const eventFields = [
      { label: "Celo", value: "Celo" },
      { label: "Monta", value: "Monta" },
      { label: "Parto", value: "Parto" },
      { label: "Posible parto", value: "Posible parto" },
    ];

    const treatmentsFields = [
      { label: "Vacuna", value: "Vacuna" },
      { label: "Desparasitación", value: "Desparasitación" },
      { label: "Medicación", value: "Medicación" },
      { label: "Otros", value: "Otros" },
    ];

    return (
      <>
        {/* Checkbox para cada campo del evento */}
        <InputLabel id="evento-select-label">Tratamientos</InputLabel>
        {treatmentsFields.map((field) => (
          <FormControlLabel
            key={field.value}
            control={
              <Checkbox
                checked={checkBoxOptions[field.value] || false}
                onChange={handleChangeCheckBox}
                value={field.value}
              />
            }
            label={field.label}
          />
        ))}
        <br />
        {/* Checkbox para cada campo del evento */}
        {eventFields.map((field) => (
          <FormControlLabel
            key={field.value}
            control={
              <Checkbox
                checked={checkBoxOptions[field.value] || false}
                onChange={handleChangeCheckBox}
                value={field.value}
              />
            }
            label={field.label}
          />
        ))}
        <br />
        <br />
        {/* Buttons to select and remove all options */}
        <Button
          variant="outlined"
          startIcon={<CheckBox />}
          onClick={handleSeletAllCalendar}
          style={{ marginRight: "10px", marginTop: "10px" }}
        >
          Select All
        </Button>
        <Button
          variant="outlined"
          startIcon={<CheckBoxOutlineBlank />}
          onClick={handleRemoveSeletCalendar}
          style={{ marginTop: "10px" }}
        >
          Remove All
        </Button>
      </>
    );
  };

  return (
    <>
      <Card>
        <CardHeader title="Pets list">
          <CardHeaderToolbar>
            <section
              className="d-flex flex-row"
              style={{ marginRight: "50px" }}
            >
              <IconButton
                onClick={() => setListView(true)}
                aria-label="List View"
              >
                {listView ? (
                  <FormatListBulletedIcon style={{ color: "orange" }} />
                ) : (
                  <FormatListBulletedIcon />
                )}
              </IconButton>
              <IconButton
                onClick={() => setListView(false)}
                aria-label="Calendar View"
              >
                {listView ? (
                  <EventIcon />
                ) : (
                  <EventIcon style={{ color: "orange" }} />
                )}
              </IconButton>
            </section>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-pet")}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {listView ? (
            <>
              <FiltersCard
                filtersContent={renderFiltersContent}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                handleClearFilters={handleClearFilters}
              />

              <Table data={getData(filterOptions, pets)} columns={columns} />
            </>
          ) : (
            <>
              <FiltersCard
                filtersContent={renderCheckBoxContent}
                collapsed={collapsedCalendar}
                setCollapsed={setCollapsedCalendar}
              />
              <FullCalendarView pets={pets} checkBoxOptions={checkBoxOptions} />
            </>
          )}
          <ConfirmDialog
            title={`Are you sure you want to ${
              petId?.active ? "disable" : "enable"
            } this pet?`}
            open={openConfirmDialog === 1}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              changeStatusPet(petId.id, !petId?.active)
                .then((res) => {
                  if (res.status === 200) {
                    postLog("pets-list", "change-status", user.fullName);
                    alertSuccess({
                      title: `${petId?.active ? "Disabled!" : "Enabled!"}`,
                      customMessage: `Pet ${
                        petId?.active ? "disabled" : "enabled"
                      } successfully`,
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: `Could not ${
                      petId?.active ? "disable" : "enable"
                    } pet.`,
                  });
                });
            }}
          />
          <ConfirmDialog
            title={"Are you sure you want to remove this pet?"}
            open={openConfirmDialog === 2}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deletePet(petId)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "Pet removed successfully.",
                    });
                    postLog("Pet", "delete", user.fullName);
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not remove pet.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
