import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createTheme,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  CardMedia,
} from "@material-ui/core";
import Table, { buttonsStyle } from "../../../components/tables/table";
import { useHistory, useParams } from "react-router-dom";
import { deletePet, getPets, postPet, updatePet } from "../../../../api/pet";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertInfo, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { useSelector } from "react-redux";
import { postLog } from "../../../../api/log";
import { getCategories } from "../../../../api/category";
import PetsTreatmentPopUp from "../../../components/petsTreatment/PetsTreatmentPopUp";
import { SERVER_URL } from "../../../../api";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import {
  Delete,
  InsertDriveFile,
  PhotoLibrary,
  Visibility,
} from "@material-ui/icons";
import ImagePickerDialog from "../../../components/dialogs/ImagePickerDialog";
import FilePickerDialog from "../../../components/dialogs/FilePickerDialog";
import ItemCountBadge from "../../../components/ItemCountBadge";
import PetsEventPopUp from "../../../components/petsTreatment/PetsEventPopUp";

// Create theme for delete button (red)
const theme = createTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getEmptyPet() {
  return {
    name: "",
    gender: "",
    color: "",
    birthdate: null,
    loe: "",
    chip: "",
    breeder: "",
    leishmania: false,
    nCub: { value: 0, observations: "" },
    nDeadCub: { value: 0, observations: "" },
    acquisition: { data: null, observations: "" },
    departure: { data: null, observations: "" },
    category: { _id: null }, //raza
    mother: { _id: null },
    father: { _id: null },
    heat: [],
    breeding: [],
    birthDates: [],
    potentialBirthDates: [],
    treatments: [],
    active: true,
  };
}

export default function EditPetsPage() {
  const [pet, setPet] = useState(getEmptyPet());
  const [petsM, setPetsM] = useState([]);
  const [petsH, setPetsH] = useState([]);
  const [categories, setCategories] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAltaCelo, setShowAltaCelo] = useState(false);
  const [newAltaCelo, setNewAltaCelo] = useState(false);
  const [showAltaMonta, setShowAltaMonta] = useState(false);
  const [newAltaMonta, setNewAltaMonta] = useState(false);
  const [showAltaPosibleParto, setShowAltaPosibleParto] = useState(false);
  const [newAltaPosibleParto, setNewAltaPosibleParto] = useState(false);
  const [showAltaParto, setShowAltaParto] = useState(false);
  const [newAltaParto, setNewAltaParto] = useState(false);
  const [newTreatment, setNewTreatment] = useState(false);
  const [currentTreatment, setCurrentTreatment] = useState({});
  const [currentHeat, setCurrentHeat] = useState({});
  const [currentBreeding, setCurrentBreeding] = useState({});
  const [currentPotentialBirthDate, setCurrentPotentialBirthDate] = useState(
    {}
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openViewImagesDialog, setOpenViewImagesDialog] = useState(false);
  const [openViewFilesDialog, setOpenViewFilesDialog] = useState(false);
  const [currentBirthDate, setCurrentBirthDate] = useState({});
  const petId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await getCategories();
        if (categoryResponse.status === 200) {
          const categories = categoryResponse.data;
          setCategories(categories);
        }

        const petResponse = await getPets();
        if (petResponse.status === 200) {
          const pets = petResponse.data;
          const resPet = pets.find((p) => p._id.toString() === petId) || {};
          const petsH = pets.filter(
            (p) => p.gender === "Hembra" && p !== resPet
          );
          const petsM = pets.filter(
            (p) => p.gender === "Macho" && p !== resPet
          );

          setPetsM(petsM);
          setPetsH(petsH);
          setPet(resPet);
        }
      } catch (error) {
        alertError({ error: error, customMessage: "Could not get pet." });
        history.push("/pets");
      } finally {
        disableLoadingData();
      }
    };

    fetchData();
  }, [petId, disableLoadingData, history]);

  const columnsEvent = [
    { dataField: "data", text: "Fecha", sort: true },
    { dataField: "observations", text: "Observaciones", sort: false },
    { dataField: "info", text: "", formatter: buttonFormatterEvent },
  ];
  function getDateEvent(events, type) {
    let data = [];
    const stringNull = "---------";
    for (const [index, event] of events.entries()) {
      const elem = {};
      elem.observations = event.observations || stringNull;
      elem.data = event.data
        ? new Date(event.data).toISOString().split("T")[0]
        : stringNull;
      elem.info = { id: index, type: type };
      data = data.concat(elem);
    }
    return data;
  }
  function buttonFormatterEvent(cell) {
    const event = pet[cell.type][cell.id];
    event.data = event.data
      ? new Date(event.data).toISOString().split("T")[0]
      : "--------";
    const functionHandlers = {
      heat: {
        change: handleChangeHeat,
        delete: handleDeleteHeat,
        new: setNewAltaCelo,
        show: setShowAltaCelo,
      },
      breeding: {
        change: handleChangeBreeding,
        delete: handleDeleteBreeding,
        new: setNewAltaMonta,
        show: setShowAltaMonta,
      },
      potentialBirthDates: {
        change: handleChangePotentialBirthDate,
        delete: handleDeletePotentialBirthDate,
        new: setNewAltaPosibleParto,
        show: setShowAltaPosibleParto,
      },
      birthDates: {
        change: handleChangeBirthDate,
        delete: handleDeleteBirthDate,
        new: setNewAltaParto,
        show: setShowAltaParto,
      },
    };
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              const handler = functionHandlers[cell.type]?.change;
              if (handler) {
                handler(event);
              }
              const handlerNew = functionHandlers[cell.type]?.new;
              if (handlerNew) {
                handlerNew(false);
              }
              const handlerShow = functionHandlers[cell.type]?.show;
              if (handlerShow) {
                handlerShow(true);
              }
            }}
          >
            <EditIcon />
          </Button>
        </Tooltip>

        <Tooltip title="Delete">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              const handler = functionHandlers[cell.type]?.delete;
              if (handler) {
                handler(event);
              }
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </>
    );
  }
  function getDate(treatments) {
    let data = [];
    const stringNull = "---------";
    for (const [index, treatment] of treatments.entries()) {
      const elem = {};
      elem.imagesURLs = treatment.imagesURLs || [];
      elem.filesURLs = treatment.filesURLs || [];
      elem.type = treatment.type || stringNull;
      elem.medication = treatment.medication || stringNull;
      elem.indications = treatment.indications || stringNull;
      elem.posology = treatment.posology || stringNull;
      elem.recommendations = treatment.recommendations || stringNull;
      elem.contraindication = treatment.contraindication || stringNull;
      elem.observation = treatment.observation || stringNull;
      elem.startDate = treatment.startDate
        ? new Date(treatment.startDate).toISOString().split("T")[0]
        : stringNull;
      elem.state = treatment.state ? "Si" : "No";
      elem.id = index;
      data = data.concat(elem);
    }
    return data;
  }

  function buttonFormatter(cell) {
    const treatment = pet.treatments[cell];
    treatment.startDate = treatment.startDate
      ? new Date(treatment.startDate).toISOString().split("T")[0]
      : "--------";
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              handleChangeTreatment(treatment);
              setNewTreatment(false);
              setShowPopUp(true);
            }}
          >
            <EditIcon />
          </Button>
        </Tooltip>

        <Tooltip title="Delete">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              handleDeleteTreatment(cell);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
        <Tooltip title={"Images"}>
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              handleChangeTreatment(treatment);
              setOpenViewImagesDialog(true);
            }}
          >
            <PhotoLibrary />
            {pet.treatments[cell]?.imagesURLs?.length > 0 && (
              <ItemCountBadge
                howMany={pet.treatments[cell]?.imagesURLs?.length}
              />
            )}
          </Button>
        </Tooltip>
        <Tooltip title={"Documentation"}>
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              handleChangeTreatment(treatment);
              setOpenViewFilesDialog(true);
            }}
          >
            <InsertDriveFile />
            {pet.treatments[cell]?.filesURLs?.length > 0 && (
              <ItemCountBadge
                howMany={pet.treatments[cell]?.filesURLs?.length}
              />
            )}
          </Button>
        </Tooltip>
      </>
    );
  }

  const columns = [
    { dataField: "type", text: "Tipo", sort: true },
    { dataField: "medication", text: "Medicació", sort: true },
    { dataField: "indications", text: "Indicaciones", sort: true },
    { dataField: "posology", text: "Posologia", sort: true },
    { dataField: "recommendations", text: "Recomendaciones", sort: true },
    { dataField: "contraindication", text: "Contraindicaciones", sort: true },
    { dataField: "startDate", text: "Fecha", sort: true },
    { dataField: "state", text: "Finalizado", sort: true },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  function savePet() {
    let savePet = pet;
    if (!petId) {
      postPet(savePet)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Pet successfully created.",
            });
            postLog("Pets", `create-${petId}`, user.fullName);
            history.push("/pets");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save pet.",
          });
        });
    } else {
      updatePet(petId, savePet)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            postLog("Pets", `update-${petId}`, user.fullName);
            history.push("/pets");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }
  const handleChangeTreatment = (value) => {
    setCurrentTreatment(value);
  };

  const handleChangeHeat = (currentObj) => {
    const value = currentObj;
    setCurrentHeat(value);
  };
  const handleChangeBreeding = (currentObj) => {
    const value = currentObj;
    setCurrentBreeding(value);
  };
  const handleChangePotentialBirthDate = (currentObj) => {
    const value = currentObj;
    setCurrentPotentialBirthDate(value);
  };
  const handleChangeBirthDate = (currentObj) => {
    const value = currentObj;
    setCurrentBirthDate(value);
  };
  const handleClosePopUp = (newTreatmentObj) => {
    setShowPopUp(false);
    if (!newTreatmentObj) {
      return;
    }
    if (newTreatment) {
      setPet({
        ...pet,
        treatments: [...(pet.treatments || []), newTreatmentObj],
      });
    } else {
      setPet((prevPet) => {
        // Clonar el array para no mutar directamente el estado anterior
        const updatedTreatments = [...prevPet.treatments];
        const index = updatedTreatments.indexOf(currentTreatment);

        if (index !== -1) {
          updatedTreatments[index] = {
            ...updatedTreatments[index],
            type: newTreatmentObj.type,
            imagesURLs: newTreatmentObj.imagesURLs,
            filesURLs: newTreatmentObj.filesURLs,
            medication: newTreatmentObj.medication,
            indications: newTreatmentObj.indications,
            posology: newTreatmentObj.posology,
            recommendations: newTreatmentObj.recommendations,
            contraindication: newTreatmentObj.contraindication,
            observation: newTreatmentObj.observation,
            startDate: newTreatmentObj.startDate,
            state: newTreatmentObj.state,
          };
        }
        // Retornar el nuevo objeto pet con los tratamientos actualizados
        return {
          ...prevPet,
          treatments: updatedTreatments,
        };
      });
    }
  };

  const handleCloseEventPopUp = (newEventObj, type) => {
    let currentEvent = {};
    let newEvent = false;
    switch (type) {
      case "heat":
        newEvent = newAltaCelo;
        currentEvent = currentHeat;
        setShowAltaCelo(false);
        break;
      case "breeding":
        newEvent = newAltaMonta;
        currentEvent = currentBreeding;
        setShowAltaMonta(false);
        break;
      case "birthDates":
        newEvent = newAltaParto;
        currentEvent = currentBirthDate;
        setShowAltaParto(false);
        break;
      case "potentialBirthDates":
        newEvent = newAltaPosibleParto;
        currentEvent = currentPotentialBirthDate;
        setShowAltaPosibleParto(false);
        break;
      default:
        console.log("ERROR: ", type);
        return;
    }
    if (!newEventObj) {
      return;
    }
    if (newEvent) {
      setPet({
        ...pet,
        [type]: [...(pet[type] || []), newEventObj],
      });
    } else {
      setPet((prevPet) => {
        const updatedEvent = [...prevPet[type]];
        const index = updatedEvent.indexOf(currentEvent);
        if (index !== -1) {
          updatedEvent[index] = {
            ...updatedEvent[index],
            observations: newEventObj.observations,
            data: newEventObj.data,
          };
        }
        return {
          ...prevPet,
          [type]: updatedEvent,
        };
      });
    }
  };

  const handleDeleteTreatment = (id) => {
    const updatedTreatments = pet.treatments.filter(
      (treatment) => treatment !== pet.treatments[id]
    );
    setCurrentTreatment({});
    setPet({
      ...pet,
      treatments: updatedTreatments,
    });
  };
  const handleDeleteHeat = (selectedHeat) => {
    setPet((prevPet) => {
      const updatedHeat = prevPet.heat.filter((heat) => heat !== selectedHeat);
      return {
        ...prevPet,
        heat: updatedHeat,
      };
    });
    setCurrentHeat({});
  };
  const handleDeleteBreeding = (selectedBreeding) => {
    setPet((prevPet) => {
      const updatedBreeding = prevPet.breeding.filter(
        (breeding) => breeding !== selectedBreeding
      );
      return {
        ...prevPet,
        breeding: updatedBreeding,
      };
    });
    setCurrentBreeding({});
  };
  const handleDeletePotentialBirthDate = (selectedPotentialBirthDate) => {
    setPet((prevPet) => {
      const updatedPotentialBirthDates = prevPet.potentialBirthDates.filter(
        (potentialBirthDates) =>
          potentialBirthDates !== selectedPotentialBirthDate
      );
      return {
        ...prevPet,
        potentialBirthDates: updatedPotentialBirthDates,
      };
    });
    setCurrentPotentialBirthDate({});
  };
  const handleDeleteBirthDate = (selectedBirthDate) => {
    setPet((prevPet) => {
      const updatedBirthDates = prevPet.birthDates.filter(
        (birthDates) => birthDates !== selectedBirthDate
      );
      return {
        ...prevPet,
        birthDates: updatedBirthDates,
      };
    });
    setCurrentBirthDate({});
  };
  const handleChange = (element) => (event) => {
    const value = event.target.value;
    if (element === "birthdate" && isNaN(new Date(value))) {
      alertInfo({
        message: "Por favor, rellene con una fecha valida",
      });
      return;
    }
    if (element.includes(".")) {
      let partes = element.split(".");
      setPet({
        ...pet,
        [partes[0]]: {
          ...pet[partes[0]],
          [partes[1]]: value,
        },
      });
    } else {
      setPet({ ...pet, [element]: value });
    }
  };
  const handleChangeImage = (event) => {
    const imagesURL = event.target.files[0];
    setSelectedImage(imagesURL);

    setPet({
      ...pet,
      imagesURLs: [imagesURL],
    });
  };
  const handleSaveImage = (images) => {
    let treatments = pet.treatments;
    let index = treatments.findIndex((x) => x === currentTreatment);
    treatments[index] = {
      ...treatments[index],
      imagesURLs: images,
    };

    setPet({
      ...pet,
      treatments: treatments,
    });
  };

  const handleSaveFile = (files) => {
    let treatments = [...pet.treatments];

    let index = treatments.findIndex((x) => x === currentTreatment);
    treatments[index] = {
      ...treatments[index],
      filesURLs: files,
    };

    setPet({
      ...pet,
      treatments: treatments,
    });
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit pet"></CardHeader>
          <CardBody>
            <div className="col-md-4">
              {(selectedImage ||
                (pet?.imagesURLs && pet?.imagesURLs.length > 0)) && (
                <>
                  <Card>
                    <CardMedia
                      component="img"
                      alt="Animal"
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "auto",
                        maxHeight: "600px",
                      }}
                      image={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : `${SERVER_URL}/${pet.imagesURLs[0]}`
                      }
                    />
                  </Card>
                </>
              )}
              <label htmlFor={"upload-media"} className="ml-0 my-4">
                <input
                  style={{ display: "none" }}
                  id={"upload-media"}
                  name={"upload-media"}
                  type="file"
                  accept={"image/*"}
                  onChange={handleChangeImage}
                />
                <Button
                  style={{ marginRight: "15px" }}
                  color="secondary"
                  component="span"
                  variant="outlined"
                >
                  {selectedImage ||
                  (pet.imagesURLs && pet.imagesURLs.length > 0)
                    ? "Change media"
                    : "Upload media"}
                </Button>
              </label>
              {(selectedImage ||
                (pet.imagesURLs && pet.imagesURLs.length > 0)) && (
                <>
                  <Tooltip title={"Preview"}>
                    <Button
                      size="small"
                      onClick={() => setOpenPreviewDialog(true)}
                      style={{
                        ...buttonsStyle,
                        marginRight: "15px",
                      }}
                    >
                      <Visibility />
                    </Button>
                  </Tooltip>
                  <PreviewDialog
                    title={"Preview"}
                    open={openPreviewDialog}
                    setOpen={setOpenPreviewDialog}
                    file={selectedImage}
                    src={
                      selectedImage
                        ? URL.createObjectURL(selectedImage)
                        : `${SERVER_URL}/${pet.imagesURLs[0]}`
                    }
                  />
                  <Tooltip title={"Delete"}>
                    <Button
                      size="small"
                      onClick={() => {
                        setSelectedImage(null);
                        setPet({
                          ...pet,
                          imagesURLs: null,
                        });
                      }}
                    >
                      <Delete />
                    </Button>
                  </Tooltip>
                </>
              )}
            </div>
            <TextField
              id={`name`}
              label="Nombre"
              value={pet.name}
              onChange={handleChange("name")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              type="tel"
            />
            <br />
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="gender-select-label">Sexo</InputLabel>
              <Select
                labelId="gender-select-label"
                id="gender-select"
                value={pet.gender}
                onChange={handleChange("gender")}
                MenuProps={MenuProps}
              >
                <MenuItem key="macho" value="Macho">
                  Macho
                </MenuItem>
                <MenuItem key="hembra" value="Hembra">
                  Hembra
                </MenuItem>
              </Select>
            </FormControl>
            <br />
            <TextField
              id={`color`}
              label="Color"
              value={pet.color}
              onChange={handleChange("color")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              type="tel"
            />
            <br />
            <TextField
              id={`breeder`}
              label="Criador"
              value={pet.breeder}
              onChange={handleChange("breeder")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              type="tel"
            />
            <br />
            <TextField
              label="Fecha de nacimiento"
              type="date"
              value={
                pet?.birthdate
                  ? new Date(pet?.birthdate).toISOString().split("T")[0]
                  : ""
              }
              onChange={handleChange("birthdate")}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <TextField
              id={`loe`}
              label="LOE"
              value={pet.loe}
              onChange={handleChange("loe")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <TextField
              id={`chip`}
              label="Microchip"
              value={pet.chip}
              onChange={handleChange("chip")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={pet.leishmania}
                  onChange={() =>
                    setPet({ ...pet, leishmania: !pet.leishmania })
                  }
                  name="checkLeishmania"
                />
              }
              label="Leishmania"
            />
            <br />
            <TextField
              label="Numero de Cachorros"
              type="number"
              value={pet.nCub?.value}
              onChange={handleChange("nCub.value")}
              variant="outlined"
              margin="normal"
              InputProps={{
                inputProps: { min: 0, step: 1 }, // Puedes ajustar `min`, `max`, y `step`
              }}
            />
            <br />
            <TextField
              label="Observaciones"
              value={pet.nCub?.observations}
              onChange={handleChange("nCub.observations")}
              variant="outlined"
              margin="normal"
              multiline
              minRows={4}
            />
            <br />
            <TextField
              label="Numero de Cachorros Muertos"
              type="number"
              value={pet.nDeadCub?.value}
              onChange={handleChange("nDeadCub.value")}
              variant="outlined"
              margin="normal"
              InputProps={{
                inputProps: { min: 0, step: 1 }, // Puedes ajustar `min`, `max`, y `step`
              }}
            />
            <br />
            <TextField
              label="Observaciones"
              value={pet.nDeadCub?.observations}
              onChange={handleChange("nDeadCub.observations")}
              variant="outlined"
              margin="normal"
              multiline
              minRows={4}
            />
            <br />
            <TextField
              label="Fecha de Alta"
              type="date"
              value={
                pet?.acquisition?.data
                  ? new Date(pet?.acquisition.data).toISOString().split("T")[0]
                  : ""
              }
              onChange={handleChange("acquisition.data")}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <TextField
              label="Observaciones"
              value={pet.acquisition?.observations}
              onChange={handleChange("acquisition.observations")}
              variant="outlined"
              margin="normal"
              multiline
              minRows={4}
            />
            <br />
            <TextField
              label="Fecha de Baja"
              type="date"
              value={
                pet?.departure?.data
                  ? new Date(pet?.departure.data).toISOString().split("T")[0]
                  : ""
              }
              onChange={handleChange("departure.data")}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <TextField
              label="Observaciones"
              value={pet.departure?.observations}
              onChange={handleChange("departure.observations")}
              variant="outlined"
              margin="normal"
              multiline
              minRows={4}
            />
            <br />
            <br />
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="mother-select-label">Madre</InputLabel>
              <Select
                labelId="mother-select-label"
                id="mother-select"
                value={pet.mother}
                onChange={handleChange("mother")}
                MenuProps={MenuProps}
              >
                {petsH?.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="father-select-label">Padre</InputLabel>
              <Select
                labelId="father-select-label"
                id="father-select"
                value={pet.father}
                onChange={handleChange("father")}
                MenuProps={MenuProps}
              >
                {petsM?.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="category-select-label">Raza</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={pet?.category}
                onChange={handleChange("category")}
                MenuProps={MenuProps}
              >
                {categories?.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <InputLabel id="treatment-select-label">
              Historial de tratamientos
            </InputLabel>
            <div className="petButtonLeft">
              <button
                type="button"
                className="btn btn-primary "
                onClick={() => {
                  setNewTreatment(true);
                  setShowPopUp(true);
                }}
              >
                Add new
              </button>
            </div>
            <FormControl
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {pet.treatments && pet.treatments.length > 0 && (
                <Table data={getDate(pet.treatments)} columns={columns} />
              )}
            </FormControl>

            <PetsTreatmentPopUp
              show={showPopUp}
              onHide={handleClosePopUp}
              treatment={newTreatment ? {} : currentTreatment}
            />
            <br />
            <br />
            <InputLabel id="heat-select-label">Historial de Celos</InputLabel>
            <div className="petButtonLeft">
              <button
                type="button"
                className="btn btn-primary "
                onClick={() => {
                  setShowAltaCelo(true);
                  setNewAltaCelo(true);
                  setCurrentHeat({});
                }}
              >
                Add new
              </button>
            </div>
            <FormControl
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {pet.heat && pet.heat.length > 0 && (
                <Table
                  data={getDateEvent(pet.heat, "heat")}
                  columns={columnsEvent}
                />
              )}
            </FormControl>
            <PetsEventPopUp
              show={showAltaCelo}
              onHide={handleCloseEventPopUp}
              event={newAltaCelo ? {} : currentHeat}
              eventTitle={"Celo"}
            />
            <br />
            <br />
            <InputLabel id="breeding-select-label">
              Historial de montas
            </InputLabel>
            <div className="petButtonLeft">
              <button
                type="button"
                className="btn btn-primary "
                onClick={() => {
                  setShowAltaMonta(true);
                  setNewAltaMonta(true);
                  setCurrentBreeding({});
                }}
              >
                Add new
              </button>
            </div>
            <FormControl
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {pet.breeding && pet.breeding.length > 0 && (
                <Table
                  data={getDateEvent(pet.breeding, "breeding")}
                  columns={columnsEvent}
                />
              )}
            </FormControl>
            <PetsEventPopUp
              show={showAltaMonta}
              onHide={handleCloseEventPopUp}
              event={newAltaMonta ? {} : currentBreeding}
              eventTitle={"Monta"}
            />
            <br />
            <br />
            <InputLabel id="potentialBirthDates-select-label">
              Historial de fechas de posibles partos
            </InputLabel>
            <div className="petButtonLeft">
              <button
                type="button"
                className="btn btn-primary "
                onClick={() => {
                  setShowAltaPosibleParto(true);
                  setNewAltaPosibleParto(true);
                  setCurrentPotentialBirthDate({});
                }}
              >
                Add new
              </button>
            </div>
            <FormControl
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {pet.potentialBirthDates &&
                pet.potentialBirthDates.length > 0 && (
                  <Table
                    data={getDateEvent(
                      pet.potentialBirthDates,
                      "potentialBirthDates"
                    )}
                    columns={columnsEvent}
                  />
                )}
            </FormControl>
            <PetsEventPopUp
              show={showAltaPosibleParto}
              onHide={handleCloseEventPopUp}
              event={newAltaPosibleParto ? {} : currentPotentialBirthDate}
              eventTitle={"Posible parto"}
            />
            <br />
            <br />
            <InputLabel id="potentialBirthDates-select-label">
              Historial de fechas de partos
            </InputLabel>
            <div className="petButtonLeft">
              <button
                type="button"
                className="btn btn-primary "
                onClick={() => {
                  setShowAltaParto(true);
                  setNewAltaParto(true);
                  setCurrentBirthDate({});
                }}
              >
                Add new
              </button>
            </div>
            <FormControl
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {pet.birthDates && pet.birthDates.length > 0 && (
                <Table
                  data={getDateEvent(pet.birthDates, "birthDates")}
                  columns={columnsEvent}
                />
              )}
            </FormControl>
            <PetsEventPopUp
              show={showAltaParto}
              onHide={handleCloseEventPopUp}
              event={newAltaParto ? {} : currentBirthDate}
              eventTitle={"Parto"}
            />
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={pet.active}
                  onChange={() => setPet({ ...pet, active: !pet.active })}
                  name="checkActive"
                />
              }
              label="Active"
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/pets")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => savePet()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save pet
        </Button>
        {petId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete pet
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this pet?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deletePet(petId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Pet deleted successfully",
                      });
                      postLog("Pets", `delete-${petId}`, user.fullName);
                      history.push("/pets");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete pet.",
                    });
                  });
              }}
            />
          </>
        )}
        <ImagePickerDialog
          title={"Images"}
          open={openViewImagesDialog}
          setOpen={setOpenViewImagesDialog}
          data={
            openViewImagesDialog
              ? currentTreatment?.imagesURLs
              : [...(currentTreatment?.imagesURLs ?? [])]
          }
          editable={openViewImagesDialog}
          onSave={(images) => {
            handleSaveImage(images);
          }}
        />
        <FilePickerDialog
          title={"Files"}
          open={openViewFilesDialog}
          setOpen={setOpenViewFilesDialog}
          data={
            openViewFilesDialog
              ? currentTreatment?.filesURLs
              : [...(currentTreatment?.filesURLs ?? [])]
          }
          editable={openViewFilesDialog}
          onSave={(files) => {
            handleSaveFile(files);
          }}
        />
      </>
    );
}
