import { /* baseClient, */ authClient, API } from "../index";
import { uploadFilesGetLinks, uploadSingleFile } from "../files";
// Get all pets
export const getPets = () => {
  return authClient().get(`${API}/pet`);
};

// Get pet by id
export const getPetById = (id) => {
  return authClient().get(`${API}/pet/${id}`);
};

// Delete pet
export const deletePet = (id) => {
  return authClient().delete(`${API}/pet/${id}`);
};

// Create pet
export const postPet = async (pet) => {
  if (pet.imagesURLs && pet.imagesURLs?.length > 0) {
    let imagesURLs = await uploadFilesGetLinks(pet.imagesURLs, "pets");
    pet.imagesURLs = imagesURLs;
  }
  if (pet.treatments) {
    for (const treatment of pet.treatments) {
      if (treatment.imagesURLs && treatment.imagesURLs?.length) {
        let imagesURLs = await uploadFilesGetLinks(
          treatment.imagesURLs,
          "treatments"
        );
        treatment.imagesURLs = imagesURLs;
      }
      if (treatment.filesURLs && treatment.filesURLs?.length) {
        let filesURLs = await uploadFilesGetLinks(
          treatment.filesURLs,
          "treatments"
        );
        treatment.filesURLs = filesURLs;
      }
    }
  }
  return authClient().post(`${API}/pet`, pet);
};

// Update pet
export const updatePet = async (id, pet) => {
  if (pet.imagesURLs && pet.imagesURLs?.length) {
    let imagesURLs = await uploadFilesGetLinks(pet.imagesURLs, "pets");

    pet.imagesURLs = (pet.imagesURLs || [])
      .filter((image) => typeof image !== "object" || !(image instanceof File))
      .concat(imagesURLs);
  }
  for (const treatment of pet?.treatments) {
    if (treatment.imagesURLs && treatment.imagesURLs?.length) {
      let imagesURLs = await uploadFilesGetLinks(
        treatment.imagesURLs.filter(
          (image) => typeof image === "object" || image instanceof File
        ),
        "treatments"
      );

      treatment.imagesURLs = (treatment.imagesURLs || [])
        .filter(
          (image) => typeof image !== "object" || !(image instanceof File)
        )
        .concat(imagesURLs);
    }
    if (treatment.filesURLs && treatment.filesURLs?.length) {
      let filesURLs = await uploadFilesGetLinks(
        treatment.filesURLs.filter(
          (file) => typeof file === "object" || file instanceof File
        ),
        "treatments"
      );

      treatment.filesURLs = (treatment.filesURLs || [])
        .filter((file) => typeof file !== "object" || !(file instanceof File))
        .concat(filesURLs);
    }
  }

  return authClient().put(`${API}/pet/${id}`, pet);
};

// change pet status
export const changeStatusPet = (id) => {
  return authClient().put(`${API}/pet/changeStatus/${id}`);
};
