import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import { Delete, Visibility, CloudDownload } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { SERVER_URL } from "../../../api";
import { buttonsStyle } from "../tables/table";
import PreviewDialog from "./PreviewDialog";

const FilePickerDialog = (props) => {
  const { title, open, setOpen, data, onSave, editable = true } = props;

  const [selectedFiles, setSelectedFiles] = useState(data || []);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  useEffect(() => {
    if (open) setSelectedFiles(data || []);
  }, [open]);

  const handleDeleteFile = (index) => {
    let newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="table-dialog"
    >
      <DialogTitle id="table-dialog">{title}</DialogTitle>
      <DialogContent>
        {selectedFiles?.map((_, index) => {
          return (
            <>
              {editable && (
                <label htmlFor={"upload-file" + index} key={index}>
                  <input
                    style={{ display: "none" }}
                    id={"upload-file" + index}
                    name={"upload-file" + index}
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setSelectedFiles((prevSelectedFiles) => {
                        const newSelectedFiles = [...prevSelectedFiles];
                        newSelectedFiles[index] = file;
                        return newSelectedFiles;
                      });
                    }}
                    key={index}
                  />
                  <Button
                    style={{ marginRight: "15px" }}
                    color="secondary"
                    component="span"
                    variant="outlined"
                  >
                    {selectedFiles && selectedFiles[index]
                      ? "Change file " + (index + 1)
                      : "Upload file " + (index + 1)}
                  </Button>
                </label>
              )}
              {selectedFiles && selectedFiles[index] && (
                <>
                  <Tooltip title={"Preview file " + (index + 1)}>
                    {typeof selectedFiles[index] === "string" &&
                    selectedFiles[index].endsWith(".docx") ? (
                      <Button
                        size="small"
                        style={buttonsStyle}
                        onClick={() => {
                          const downloadLink = document.createElement("a");
                          downloadLink.href = `${SERVER_URL}/${selectedFiles[index]}`;
                          downloadLink.download = selectedFiles[index];
                          downloadLink.click();
                        }}
                      >
                        <CloudDownload />
                      </Button>
                    ) : typeof selectedFiles[index] !== "string" &&
                      selectedFiles[index].name?.endsWith(".docx") ? (
                      <Button
                        size="small"
                        style={buttonsStyle}
                        onClick={() => {
                          const downloadLink = document.createElement("a");
                          downloadLink.href = URL.createObjectURL(
                            selectedFiles[index]
                          );
                          downloadLink.download = selectedFiles[index].name;
                          downloadLink.click();
                        }}
                      >
                        <CloudDownload />
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        onClick={() => {
                          setOpenPreviewDialog(index);
                        }}
                        style={buttonsStyle}
                      >
                        <Visibility />
                      </Button>
                    )}
                  </Tooltip>
                  <PreviewDialog
                    title={"Preview " + (index + 1)}
                    open={openPreviewDialog === index}
                    setOpen={setOpenPreviewDialog}
                    file={selectedFiles && selectedFiles[index]}
                    src={
                      selectedFiles &&
                      selectedFiles[index] &&
                      typeof selectedFiles[index] === "string"
                        ? `${SERVER_URL}/${selectedFiles[index]}`
                        : URL.createObjectURL(selectedFiles[index])
                    }
                  />
                  {editable && (
                    <Tooltip title="Delete">
                      <Button
                        size="small"
                        style={buttonsStyle}
                        onClick={() => handleDeleteFile(index)}
                      >
                        <Delete />
                      </Button>
                    </Tooltip>
                  )}
                  <span className="ml-3">
                    {selectedFiles &&
                      selectedFiles[index] &&
                      (selectedFiles[index]?.name ||
                        selectedFiles[index]?.split(/-(.*)/s)[1] ||
                        "")}
                  </span>
                  <br />
                </>
              )}
            </>
          );
        })}
        {selectedFiles?.length > 0 && (
          <>
            <br />
            <br />
          </>
        )}
        {editable ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSelectedFiles([...selectedFiles, null]);
            }}
          >
            Add file
          </Button>
        ) : (
          !selectedFiles?.length > 0 && <div>No files available</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
        {editable && (
          <Button
            className="ml-3"
            onClick={() => {
              onSave(selectedFiles);
              setOpen(false);
              setSelectedFiles([]);
            }}
            variant="outlined"
            color="primary"
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default FilePickerDialog;
