import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControl,
  DialogActions,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { alertInfo } from "../../../utils/logger";

import { InsertDriveFile, PhotoLibrary } from "@material-ui/icons";
import ItemCountBadge from "../ItemCountBadge";
import { buttonsStyle } from "../tables/table";
import FilePickerDialog from "../dialogs/FilePickerDialog";
import ImagePickerDialog from "../dialogs/ImagePickerDialog";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

const getEmptyTreatment = () => ({
  type: "",
  medication: "",
  indications: "",
  posology: "",
  recommendations: "",
  contraindication: "",
  observation: "",
  startDate: "",
  state: false,
});

function getTypeOptions() {
  return ["Vacuna", "Desparasitación", "Medicación", "Otros"];
}

const PetsTreatmentPopUp = ({ show, onHide, treatment }) => {
  const [petsTreatment, setPetsTreatment] = useState(getEmptyTreatment());
  const [types, setTypes] = useState(getTypeOptions());
  const [type, setType] = useState("");
  const [imagesURLs, setImagesURLs] = useState([]);
  const [filesURLs, setFilesURLs] = useState([]);
  const [medication, setMedication] = useState("");
  const [indications, setIndications] = useState("");
  const [posology, setPosology] = useState("");
  const [recommendations, setRecommendations] = useState("");
  const [contraindication, setContraindication] = useState("");
  const [observation, setObservation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [state, setState] = useState(false);
  const [openViewImagesDialog, setOpenViewImagesDialog] = useState(false);
  const [openViewFilesDialog, setOpenViewFilesDialog] = useState(false);
  const user = useSelector((store) => store.authentication.user);

  const handleSubmit = () => {
    const petsTreatmentCopy = getEmptyTreatment();
    petsTreatmentCopy.type = type;
    petsTreatmentCopy.imagesURLs = imagesURLs;
    petsTreatmentCopy.filesURLs = filesURLs;
    petsTreatmentCopy.medication = medication;
    petsTreatmentCopy.indications = indications;
    petsTreatmentCopy.posology = posology;
    petsTreatmentCopy.recommendations = recommendations;
    petsTreatmentCopy.contraindication = contraindication;
    petsTreatmentCopy.observation = observation;
    petsTreatmentCopy.startDate = startDate;
    petsTreatmentCopy.state = state;
    if (
      !petsTreatmentCopy.type ||
      !petsTreatmentCopy.medication ||
      !petsTreatmentCopy.indications ||
      !petsTreatmentCopy.posology ||
      !petsTreatmentCopy.recommendations ||
      !petsTreatmentCopy.contraindication ||
      !petsTreatmentCopy.observation ||
      !petsTreatmentCopy.startDate
    ) {
      alertInfo({
        message: "Por favor, rellene todos los campos",
      });
      return;
    } else {
      onHide(petsTreatmentCopy);
    }
  };
  const handleChange = (element) => (event) => {
    switch (element) {
      case "state":
        setState((prevState) => !prevState);
        break;
      case "type":
        setType(event.target.value);
        break;
      case "medication":
        setMedication(event.target.value);
        break;
      case "indications":
        setIndications(event.target.value);
        break;
      case "posology":
        setPosology(event.target.value);
        break;
      case "recommendations":
        setRecommendations(event.target.value);
        break;
      case "contraindication":
        setContraindication(event.target.value);
        break;
      case "observation":
        setObservation(event.target.value);
        break;
      case "startDate":
        setStartDate(event.target.value);
        break;
      default:
        console.warn(`Elemento no reconocido: ${element}`);
    }
  };

  useEffect(() => {
    if (
      treatment &&
      typeof treatment === "object" &&
      Object.keys(treatment)?.length !== 0 &&
      treatment?.type
    ) {
      setType(treatment.type);
      setImagesURLs(treatment.imagesURLs);
      setFilesURLs(treatment.filesURLs);
      setMedication(treatment.medication);
      setIndications(treatment.indications);
      setPosology(treatment.posology);
      setRecommendations(treatment.recommendations);
      setContraindication(treatment.contraindication);
      setObservation(treatment.observation);
      setStartDate(treatment.startDate);
      setState(treatment.state);
    } else {
      setPetsTreatment(getEmptyTreatment());
      setImagesURLs([]);
      setFilesURLs([]);
      setType("");
      setMedication("");
      setIndications("");
      setPosology("");
      setRecommendations("");
      setContraindication("");
      setObservation("");
      setStartDate("");
      setState(false);
    }
  }, [treatment]);

  return (
    <Dialog
      open={show}
      // onClose={onHide}
      fullWidth={true}
      maxWidth={"lg"}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          minHeight: "80vh",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle id="form-dialog-title">Programar un Tratamiento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="overflow-hidden">
          <Grid item xs={12} md={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="treatment-select-label">
                Tipo de tratamiento
              </InputLabel>
              <Select
                labelId="treatment-select-label"
                id="treatment-select"
                value={type}
                onChange={handleChange("type")}
                MenuProps={MenuProps}
              >
                {types?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <TextField
              id={`medication`}
              label="Medicación"
              value={medication}
              onChange={handleChange("medication")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              type="tel"
            />
            <br />
            <TextField
              id={`posology`}
              label="Posologia"
              value={posology}
              onChange={handleChange("posology")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              type="tel"
            />
            <br />
            <TextField
              id={`contraindication`}
              label="Contraindicaciones"
              value={contraindication}
              onChange={handleChange("contraindication")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              type="tel"
            />
            <br />
            <FormControl className="d-flex flex-row justify-content-around">
              <TextField
                margin="normal"
                id="date"
                label="Fecha de inicio"
                type="date"
                value={startDate}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                className="mx-2"
                onChange={handleChange("startDate")}
              />
            </FormControl>

            <br />
            <DialogActions>
              <FormLabel className="d-flex flex-row justify-content-center align-items-center">
                Marcar como completado
                <Checkbox
                  color="primary"
                  onClick={handleChange("state")}
                  checked={state}
                />
              </FormLabel>
              <Button
                onClick={() => {
                  onHide(null);
                }}
                color="primary"
              >
                Cancelar
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Guardar
              </Button>
            </DialogActions>
          </Grid>
          <Grid item xs={12} md={6}>
            <br />
            <TextField
              id={`indications`}
              label="Indicaciones"
              value={indications}
              onChange={handleChange("indications")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              type="tel"
            />
            <br />
            <TextField
              id={`recommendations`}
              label="Recomendaciones"
              value={recommendations}
              onChange={handleChange("recommendations")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              type="tel"
            />
            <br />
            <TextField
              id={`observation`}
              label="Observaciones"
              value={observation}
              onChange={handleChange("observation")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              type="tel"
              multiline
              minRows={4}
            />
            <br />
            <Tooltip title={"Images"}>
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setOpenViewImagesDialog(true);
                }}
              >
                <PhotoLibrary />
                {imagesURLs?.length > 0 && (
                  <ItemCountBadge howMany={imagesURLs?.length} />
                )}
              </Button>
            </Tooltip>
            <Tooltip title={"Documentation"}>
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setOpenViewFilesDialog(true);
                }}
              >
                <InsertDriveFile />
                {filesURLs?.length > 0 && (
                  <ItemCountBadge howMany={filesURLs?.length} />
                )}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <ImagePickerDialog
          title={"Images"}
          open={openViewImagesDialog}
          setOpen={setOpenViewImagesDialog}
          data={openViewImagesDialog ? imagesURLs : [...(imagesURLs ?? [])]}
          editable={openViewImagesDialog}
          onSave={(images) => {
            setImagesURLs(images);
          }}
        />
        <FilePickerDialog
          title={"Files"}
          open={openViewFilesDialog}
          setOpen={setOpenViewFilesDialog}
          data={openViewFilesDialog ? filesURLs : [...(filesURLs ?? [])]}
          editable={openViewFilesDialog}
          onSave={(files) => {
            setFilesURLs(files);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default PetsTreatmentPopUp;
