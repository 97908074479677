import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControl,
  DialogActions,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { alertInfo } from "../../../utils/logger";

import { InsertDriveFile, PhotoLibrary } from "@material-ui/icons";
import ItemCountBadge from "../ItemCountBadge";
import { buttonsStyle } from "../tables/table";
import FilePickerDialog from "../dialogs/FilePickerDialog";
import ImagePickerDialog from "../dialogs/ImagePickerDialog";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

const getEmptyEvent = () => ({
  observations: "",
  data: "",
});

const PetsEventPopUp = ({ show, onHide, event, eventTitle }) => {
  const [petsEvent, setPetsEvent] = useState(getEmptyEvent());
  const [observations, setObservations] = useState("");
  const [data, setData] = useState("");
  const [type, setType] = useState("");
  const user = useSelector((store) => store.authentication.user);

  const handleSubmit = () => {
    const petsEventCopy = getEmptyEvent();
    petsEventCopy.observations = observations;
    petsEventCopy.data = data;
    if (!petsEventCopy.data) {
      alertInfo({
        message: "Por favor, rellene todos los campos",
      });
      return;
    } else {
      onHide(petsEventCopy, type);
    }
  };
  const handleChange = (element) => (event) => {
    switch (element) {
      case "observations":
        setObservations(event.target.value);
        break;
      case "data":
        setData(event.target.value);
        break;
      default:
        console.warn(`Elemento no reconocido: ${element}`);
    }
  };

  useEffect(() => {
    if (
      event &&
      eventTitle &&
      typeof event === "object" &&
      Object.keys(event)?.length !== 0 &&
      event?.data
    ) {
      setObservations(event.observations);
      setData(event.data);
    } else {
      setPetsEvent(getEmptyEvent());
      setObservations("");
      setData("");
    }
    switch (eventTitle) {
      case "Celo":
        setType("heat");
        break;
      case "Monta":
        setType("breeding");
        break;
      case "Parto":
        setType("birthDates");
        break;
      case "Posible parto":
        setType("potentialBirthDates");
        break;
      default:
        console.log("ERROR: ", eventTitle);
        break;
    }
  }, [event]);

  return (
    <Dialog
      open={show}
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        style: {
          padding: "20px",
        },
      }}
    >
      <DialogTitle style={{ paddingBottom: "8px" }}>
        Programar {eventTitle}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label={`Fecha de ${eventTitle}`}
              type="date"
              value={data ? new Date(data).toISOString().split("T")[0] : ""}
              onChange={handleChange("data")}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginBottom: "16px" }}
            />
            <TextField
              label="Observaciones"
              value={observations}
              onChange={handleChange("observations")}
              variant="outlined"
              multiline
              minRows={3}
              fullWidth
              style={{ marginBottom: "16px" }}
            />
            <DialogActions
              style={{ justifyContent: "center", padding: "8px 0" }}
            >
              <Button onClick={() => onHide(null, type)} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Guardar
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PetsEventPopUp;
