import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";

// import { updateAccionComercial } from "../../../api/accionComercial";
import { combineDateAndTime, formatDate, formatTime } from "./helpers";

export default function FullCalendarView({ pets, checkBoxOptions }) {
  const user = useSelector((state) => state.authentication.user);
  const [eventos, setEventos] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const handleEventChange = (info) => {
    const pet = pets.find((pet) => pet._id === info.event.id);
    if (!pet) {
      console.error("Action not found with ID:", info.event.id);
      return;
    }

    const fechaInicio = new Date(info.event.start);
    const fechaFin = new Date(info.event.end);

    const updatedAccion = {
      ...pet,
      fechaInicio: formatDate(fechaInicio),
      horaInicio: formatTime(fechaInicio),
      fechaFin: formatDate(fechaFin),
      horaFin: formatTime(fechaFin),
    };

    // updateAccionComercial(info.event.id, updatedAccion)
    //   .then((res) => console.log("Updated action:", res.data))
    //   .catch((error) => console.error(error));

    // console.log("Updated action:", updatedAccion);
  };
  function getClassForTreatmentType(type) {
    switch (type) {
      case "Vacuna":
        return "vacuna-event";
      case "Desparasitación":
        return "desparasitacion-event";
      case "Medicación":
        return "medicacion-event";
      case "Otros":
        return "otros-event";
      default:
        return "";
    }
  }

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    actualizarEventos();
  }, [pets, checkBoxOptions]);

  function actualizarEventos() {
    const newEventos = pets?.flatMap((pet) => [
      // Agregar tratamientos que cumplen con checkBoxOptions
      ...pet.treatments
        .filter((treatment) => checkBoxOptions[treatment.type])
        .map((treatment) => ({
          name: pet.name,
          title: treatment.type,
          medication: treatment.medication,
          indications: treatment.indications,
          posology: treatment.posology,
          recommendations: treatment.recommendations,
          contraindication: treatment.contraindication,
          observation: treatment.observation,
          start: new Date(treatment.startDate).toISOString().split("T")[0],
          id: pet._id,
          classNames: getClassForTreatmentType(treatment.type),
        })),

      // Agregar Montas solo si checkBoxOptions.Monta es true
      ...(checkBoxOptions["Monta"]
        ? pet.breeding.map((breeding) => ({
            name: pet.name,
            title: "Monta",
            observations: breeding.observations,
            start: new Date(breeding.data).toISOString().split("T")[0],
            id: pet._id,
            classNames: "breeding-event",
          }))
        : []),

      // Agregar Celo solo si checkBoxOptions.Celo es true
      ...(checkBoxOptions["Celo"]
        ? pet.heat.map((heat) => ({
            name: pet.name,
            title: "Celo",
            observations: heat.observations,
            start: new Date(heat.data).toISOString().split("T")[0],
            id: pet._id,
            classNames: "heat-event",
          }))
        : []),

      // Agregar posible parto solo si checkBoxOptions["Posible parto"] es true
      ...(checkBoxOptions["Posible parto"]
        ? pet.potentialBirthDates.map((potentialBirthDates) => ({
            name: pet.name,
            title: "Posible parto",
            observations: potentialBirthDates.observations,
            start: new Date(potentialBirthDates.data)
              .toISOString()
              .split("T")[0],
            id: pet._id,
            classNames: "potentialBirthDates-event",
          }))
        : []),

      // Agregar Parto solo si checkBoxOptions.Parto es true
      ...(checkBoxOptions["Parto"]
        ? pet.birthDates.map((birthDate) => ({
            name: pet.name,
            title: "Parto",
            observations: birthDate.observations,
            start: new Date(birthDate.data).toISOString().split("T")[0],
            id: pet._id,
            classNames: "birthDate-event",
          }))
        : []),
    ]);
    setEventos(newEventos);
    setRefresh(true);
  }

  return (
    <>
      {!refresh && (
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          events={eventos}
          editable={true}
          selectable={true}
          eventChange={handleEventChange}
          eventClick={(info) => {
            const pet = pets.find((pet) => pet._id === info.event.id);
            // setCurrentAction(pet);
            // setCurrentPropertyId(pet?._id || null);
            // setShow(true);
          }}
          eventContent={(eventInfo) => {
            const {
              name,
              medication,
              indications,
              posology,
              recommendations,
              contraindication,
              observation,
              observations,
            } = eventInfo.event.extendedProps;

            return (
              <div style={{ padding: "5px", borderRadius: "4px" }}>
                <div>
                  <strong>{eventInfo.event.title}</strong>{" "}
                  {/* Tipo de evento */}
                </div>
                <strong>{name}</strong>
                {/* Nombre de la mascota */}
                {/* Si el evento es diferente de tipo tratamiento, mostrar solo la Observación */}
                {eventInfo.event.title === "Celo" ||
                eventInfo.event.title === "Monta" ||
                eventInfo.event.title === "Parto" ||
                eventInfo.event.title === "Posible parto" ? (
                  <div>
                    <strong>Observaciones:</strong> {observations}
                  </div>
                ) : (
                  <>
                    <div>
                      <strong>Medicación:</strong> {medication}
                    </div>

                    <div>
                      <strong>Indicaciones:</strong> {indications}
                    </div>

                    <div>
                      <strong>Posología:</strong> {posology}
                    </div>

                    <div>
                      <strong>Recomendaciones:</strong> {recommendations}
                    </div>

                    <div>
                      <strong>Contraindicación:</strong> {contraindication}
                    </div>

                    <div>
                      <strong>Observación:</strong> {observation}
                    </div>
                  </>
                )}
              </div>
            );
          }}
        />
      )}
    </>
  );
}
